.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 70px;
}

.balance {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    cursor: pointer !important;
}