.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow-y: auto;
    background-color: rgb(241, 240, 240);
}


.mobileMoneyLogosRow {
    display: flex;
}

.mobileMoneyLogo {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    background-color: white;
    margin-top: 38px;
}

.mobileMoneyLogo:not(:last-child) {
    margin-right: 30px;
}
