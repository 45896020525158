.dashCard {
    border: 1px solid #dedede;
    padding: 0px 12px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 320px;
    height: 75px;
    box-sizing: border-box;
}

.dashCard:not(:last-child) {
    margin-right: 25px;
}

.dashCard div {
    width: 100%;
}

.dashCard img {
    border-radius: 6px;
    margin-right: 15px;
}

.dashCardHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
}


.dashCardIcon {
    font-size: '40px';
    color: '#f2711c';
}

.dashCardText {
    font-size: '20px';
    font-weight: 'bold';
}


.dashCardTitle {
    font-size: '20px';
    font-weight: 'bold';
    margin-bottom: '10px';
}

.loaderWrapper {
    margin-top: 12px;
}