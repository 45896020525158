.footerWrapper {
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    position: absolute;
    bottom: 0;
    padding-left: 20%;
    padding-right: 20%;
}

.footerWrapper p {
    font-size: 18px;
}