.profileWrapper {
    padding-right: 10%;
}

.companyTitle {
    display: flex;
    align-items: center;
    font-size: 35px;
}

.companyEditBtn {
     cursor: pointer;
}

.externalLink {
    display: none !important;
}

p:hover .externalLink {
    display: inline !important;
}

.companyAdmin {
    font-size: 20px;
    color: #000000DE;
    border-bottom: 1px solid #F1F0F0;
}

.companyAdmin::after,
.separator::after {
    content: "";
    height: 10px;
    display: block;
    border-bottom: 1px solid #dedede;
}

.adminInfoRow {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    height: 33px;
    width: 30%;
}

.adminInfoRow strong {
    flex-basis: 50%;
    /* background-color: red; */
}
