.agencyItem {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    justify-content: space-between;
}

.agencyName {
    flex-basis: 22%;
    /* background-color: green; */
}


.agencyArea {
    flex-basis: 12%;
    /* background-color: red; */
}

.agencyDept {
    flex-basis: 10%;
    /* background-color: blue; */
}

.agencyTills {
    flex-basis: 10%;
    /* background-color: yellow; */
}

.actions {
    flex-basis: 20%;
    text-align: right;
    /* background-color: pink; */
}