.container {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.openTillButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    width: 100%;
}

.openedTillContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.uploadButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 7px 0px;
    border-radius: 4px;
    background-color: #f2f2f2;
    width: 165px;
    cursor: pointer;
}