.centeredContent {
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    justify-content: center !important;
}

.inputLabel {
    cursor: pointer;
}

.cniPreview {
    width: 260px;
    height: 170px;
    max-width: 270px;
    max-height: 180px;
    object-fit: cover;
}