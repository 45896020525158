.areaItem {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    justify-content: space-between;
}

.areaName {
    flex-basis: 25%;
}

.areaAgencies {
    flex-basis: 10%;
}

.areaEmployees {
    flex-basis: 10%;
}

.areaTills {
    flex-basis: 10%;
}

.areaRegion {
    flex-basis: 10%;
}

.actions {
    flex-basis: 25%;
    text-align: right;
}