.container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(240, 243, 245);
}

.content {
    text-align: center;
}

.title {
    font-size: 40px;
}