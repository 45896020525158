.wrapper {
    display: flex;
    justify-content: space-between;
    min-height: 700px;
    /* gap: 30px; */
}

.left {
    flex-basis: 100%;
    border-radius: 5px;
}

.left .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 35px;
}

.right {
    flex-basis: 0%;
    border-radius: 5px;
    overflow: hidden;
    transition: flex-basis 0.6s ease-in-out
}

.resized {
    margin-left: 30px;
    flex-basis: 32% !important;
    border: 1px solid #dedede;
}

.searchbar {
    width: 70%;
    margin-right: 20px;
}

.dropdown {
    flex-basis: 20%;
    border: 1px solid #dedede;
    padding: 8px 25px;
    border-radius: 4px;
    background-color: #f6f6f6;
    min-width: 115px;
}