body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


/* ===================================== Misc ================================*/

.container {
  padding: 1% 2%;
  box-sizing: border-box;
}

.hidden {
  display: none;
}

/* ===================================== Margins ================================*/

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.my-5 {
  margin: 5px 0 !important;
}

.p-5 {
  padding: 5px !important;
}

.p-20 {
  padding: 20px !important;
}

/* ================================= Sizes ======================================*/

.size-13 {
  font-size: 13px !important;
}

.size-14 {
  font-size: 14px !important;
}

.size-15 {
  font-size: 15px !important;
}

.size-16 {
  font-size: 16px !important;
}

.size-17 {
  font-size: 17px !important;
}

.size-18 {
  font-size: 18px !important;
}

.size-22 {
  font-size: 22px !important;
}

/* ================================ Heights =================================== */

.h-500 {
  height: 500px;
}

/* ===== Miscellanous ===== */

.icon-wrapper {
  padding: 5px;
  background-color: #efefef;
  border-radius: 8px;
  box-sizing: border-box;
}
.cursor-pointer {
  cursor: pointer;
}

.backLink a {
  color: black;
}

.actionButtonRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.text-bold {
  font-weight: bold !important;
}

.hov-underline-pointer:hover {
  text-decoration: underline;
  cursor: pointer;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.mobileMoneyLogo {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  background-color: white;
  margin-top: 38px;
}

/* =================================== Borders =================================== */

.borderless {
  border: none !important;
  box-shadow: none !important;
}

.border-grey {
  border: 1px solid #dedede;
}


/* =================================== Colors ============================== */

.white {
  color: #ffffff;
}

.orange {
  color: #f2711c ;
}

.orange-2 {
  color: #e55b00;
}

.saumon {
  color: #fa541c;
}

.black {
  color: #000000;
}

/* ================= Radius ================= */

.radius-4 {
  border-radius: 4px !important;
}

.radius-6 {
  border-radius: 6px !important;
}

.radius-8 {
  border-radius: 8px !important;
}

/* ================================ Flex Box =================================== */

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.vertical-center {
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-center-v {
  display: flex;
  align-items: center;
}

.flex-center-column {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* ============================== UI Framework Override ============================= */

.ui.secondary.menu .item {
  font-size: 18px;
  font-weight: bold !important;
}

.ui.toggle.checkbox input:focus:checked~label:before {
  background-color: #e55b00 !important;
}

.ui.form .disabled.field {
  opacity: 1 !important;
}

.ui.checkbox input.hidden+label {
  font-size: 17px !important;
}

.ui.radio.checkbox label:before,
.ui.radio.checkbox input:focus:checked~label:before {
  transform: scale(1.2) !important;
}

