.cardRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 40px;
    overflow-x: auto;
    padding-bottom: 5px;
}


.cardRow::-webkit-scrollbar {
    width: 1px;
    height: 0px;
  }


.statRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 60px;
    height: 520px;
}

.lineChart {
    flex-basis: 71%;
    height: 100%;
    border: 1px solid #dedede;
    border-radius: 10px;
    padding: 20px 10px;
}

.statistics {
    flex-basis: 26%;
    height: 100%;
    border: 1px solid #dedede;
    border-radius: 6px;
    padding: 20px;
    min-width: 412px;
}


.statisticsCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.statisticsCard h3 {
    margin-top: 0px;
    margin-bottom: 0px;
}

/* .statisticsCard {
    margin-bottom: 28px;
}

.statisticsCard h3 {
    margin-bottom: 7px;
} */