.container {
    padding: 1% 2%;
    box-sizing: border-box;
}

.menubar {
    padding-bottom: 15px;
    overflow: auto;
}


.menubar::-webkit-scrollbar {
    width: 5px;
    height: 4px;
}


.menubar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.menubar::-webkit-scrollbar-thumb {
    /* background: #888; */
}

/* Handle on hover */
.menubar::-webkit-scrollbar-thumb:hover {
    background: red;
}