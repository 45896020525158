.formItem {
    display: flex;
    flex-direction: column;
}

.formItem:not(:last-child) {
    margin-bottom: 30px;
}

.h3 {
    font-size: 1.6rem;
    color: #444 !important;
}

.label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.forgotPassword {
    text-align: right;
    margin-bottom: 20px;
}

.formWrapper {
    width: 470px;
    border: 1px solid var(--skimmo-grey-2);
    border-radius: 8px;
    padding: 75px 40px;
    background-color: white;
}
